html {
    overflow-x: hidden;
    width: 100vw;
}
#covid19-page {


  ion-toolbar{
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: transparent;
    --color: white;
  }

  ion-toolbar ion-back-button,
  ion-toolbar ion-button,
  ion-toolbar ion-menu-button {
    --color: white;
  }

  a.pageTitle {
    color: white;
    cursor: pointer;
    text-decoration: none;
  }

  .covid19-header {
    position: relative;

    width: 100%;
    height: 20%;
  }

  .covid19-header .covid19-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
  }


  .covid19-header .covid19-image.opacity {
    opacity: 1;
  }

  .covid19-info p{
    color: var(--ion-color-dark);
    margin-left: 2em;
  }

  .covid19-header .banner1 {
    background-image: url("/assets/img/covid19/banner-corona-5401250.jpg");
  }

  .covid19-header .banner2 {
    background-image: url("/assets/img/covid19/banner-coronavirus-5367891.jpg");
  }
  .covid19-header .banner3 {
    background-image: url("/assets/img/covid19/banner-virus-5367173.jpg");
  }
  .covid19-header .banner4 {
    background-image: url("/assets/img/covid19/banner-corona-5174671.jpg");
  }


  .covid19-info {
    position: absolute;
    margin-top: -10px;
    border-radius: 10px;
    background: var(--ion-background-color, #fff);
  }

    ion-icon.stickyButton{
    width: 2em;
    height: 2em;
    padding: 0.3em;
    margin: 0;
    background-color:  #000000;
    border-radius: 50%;
    border:0;
    color: #FFFFFF;
    position: sticky;
    bottom: 0;
    left: calc(100vw - 4em);
      --background: transparent;
      --color: white;
  }

  .covid19-info h3 {
    margin-top: 0;
  }

  .covid19-info ion-list {
    padding-top: 0;
  }

  /*
   * iOS Only
   */

  .ios .covid19-info {
    --ion-padding: 19px;
  }

  .ios .covid19-info h3 {
    font-weight: 700;
  }

  ion-button.link, a.link {

  /* not used
  font: inherit;
  display: inline;
  */
  /*-webkit-logical-height: 1em; Chrome ignores auto, so we have to use this hack to set the correct height  */
  /*-webkit-logical-width: auto; Chrome ignores auto, but here for completeness */
    background: none;
    border: none;
    padding: 0;
    perspective-origin: 0 0;
    transform-origin: 0 0;
    text-decoration: underline;
    cursor: pointer;
    height: auto;
    width: auto;
    align-items: normal;
    text-align: start;
    background-color: rgba(0,0,0,0);
    border-color: rgb(0, 0, 238);
    border-style: none;
    box-shadow: none;
    box-sizing: content-box;
    color: rgb(0, 0, 238);
    overflow: visible;

  }

}
