
/* Style Headline-Link */
h3{
	cursor: pointer;
}

/* Style svg Images */
/* hosts svg and feature-list - this prevents display of svg w/o display-flip */
		/* height:100vh; TODO: check! seems to be needed for click/highlight function. BUT breaks layout!?! */
		/* width: calc(100vw + 310px);extend width beyond viewport for feature-list to float right of svg
.subject-container{
  position: relative;
  width: calc(100vw + 310px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
	padding-bottom: 1em
}

ion-list.feature-list{
  padding-left: 0.0em;
  margin: 0;
  width:310px;
  top:0.5em;
  right:320px;
  position:relative;
  border: 1px solid #d7d8da;

}*/
.subject-container{
  position: relative;
  width: calc(100vw - 1vw);
  display: block;
	margin-bottom: 1em;
	background-color: black;
}


svg{
  width:auto;
  max-height: 100vh;
  max-width:99vw;
}
@media (orientation: landscape) {
  svg{
    height:100vh;					/* needed for correct scaling in landscape view */
  }
}
@media (orientation: portrait) {
  svg{
    height:100%;					/* needed for correct positioning in portrait view */
  }
}

/*allow +20% height for small screens */
@media only screen and (max-height: 25em) {

	svg{
	  width:auto;
	  max-height: 120vh;
	  max-width:100vw;
	}
	@media (orientation: landscape) {
	  svg{
		height:120vh;					/* needed for correct scaling in landscape view */
	  }
	}
	@media (orientation: portrait) {
	  svg{
		height:100%;					/* needed for correct positioning in portrait view */
	  }
	}
}

/* Style Popover */
.popover p{
  padding-left: 0.25em;
}
.popover{
	height: auto;
}

/* Style List */
.bg-transparent {
  background: transparent;
}
.color-yellow{
	color:yellow;
}
.color-white{
	color:white;
}

.feature-list{ /* for div and ion-list */
  padding-left: 0.0em;
  margin: 0;
  width:310px;
  top:0.5em;
  right:0.5em;
  position:absolute;
  border: 1px solid #d7d8da;
}
div.feature-list{
	opacity: 0;
  border: 0;
	height: 9em;
	width: 9em;
	padding-top: 0.5em;
	z-index: -1;
}

div.feature-list ion-icon{
	color: white;
	vertical-align: top;
  display: block;
  margin-left: auto;
  margin-right: auto;
	margin-top: 1em;
	transform: scale(3.0);
}

@media only screen and (max-width: 520px) {
  ion-list.feature-list{
	  /* hide behind picture if screen -> picture too small z-index: -50; */
		opacity: 0;
  }
	div.feature-list{
		opacity: 1;
		z-index: 1;
	}
}
@media only screen and (max-height: 250px) {
  ion-list.feature-list{
	  /* hide behind picture if screen -> picture too small z-index: -50; */
		opacity: 0;
  }
	div.feature-list{
		opacity: 1;
		z-index: 1;
	}
}

ion-list.feature-list ion-label, ion-list.feature-list ion-item{
  border-bottom: 0px solid #d7d8da;
  border-top: 0px solid #d7d8da;
}

/* Style Boxes */
.hideElement{
	visibility: hidden;
}
div.box-text{
  padding: 1px;
  background: rgba(255,255,255,0.8);
  border: 1px solid black;
  transition: .1s;
  color: #000000;
  font-family: sans-serif;
  box-sizing: border-box;

}

a.box-text__close {
	text-decoration: none;
	position: absolute;
	right: 4px;
}

#box-text2, #box-text3, #box-text4{
  position:absolute;
  right:10px;
  top: 120px;
  height: 255px;
  width: 240px;
}

@media only screen and (min-height: 700px) {
  #box-text2, #box-text3, #box-text4{
    top: 420px;
  }
}

/* Style Layers */
.transparentLayer{
	opacity: 0;
}
.shadeElement{
	opacity: 0.3;
}
.layer2 path{
	fill: red;
}
.layer3 path{
	fill: green;
}
.layer4 path{
	fill: blue;
}
