html {
    overflow-x: hidden;
    width: 100vw;
}
#home-page {
  .preload{
    opacity: 0;
  }
  ion-toolbar{
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: transparent;
    --color: white;
  }

  ion-toolbar ion-back-button,
  ion-toolbar ion-button,
  ion-toolbar ion-menu-button {
    --color: white;
  }

  a.pageTitle {
    color: white;
    cursor: pointer;
    text-decoration: none;
  }

  .covid19-header {
    position: relative;

    width: 100%;
    height: 20%;
  }

  .covid19-header .covid19-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
  }

  .covid19-header .covid19-image.opacity {
    opacity: 1;
  }

  .covid19-info p{
    color: var(--ion-color-dark);
    margin-left: 2em;
  }

  .covid19-header .banner1 {
    background-image: url("/assets/img/covid19/banner-corona-5401250.jpg");
  }
  .covid19-header .banner1-load {
    background-image: url("/assets/img/covid19/banner-corona-5401250.png");
  }

  .covid19-header .banner2 {
    background-image: url("/assets/img/covid19/banner-coronavirus-5367891.jpg");
  }
  .covid19-header .banner3 {
    background-image: url("/assets/img/covid19/banner-virus-5367173.jpg");
  }
  .covid19-header .banner4 {
    background-image: url("/assets/img/covid19/banner-corona-5174671.jpg");
  }


}
